<template>
    <section>
        <div class="footer-header">
            <div class="container position-relative">
                <div class="row footer-nav align-items-center">
                    <div class="col-md-6">
                        <h2>{{ $j('SiteTitle') }}</h2>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <a href="/service/deposition" class="btn_main">{{ $j('Deposition') }}</a>
                    </div>
                </div>
                <div class="corner-left">
                    <div id="corner-bottomleft"></div>
                </div>
                <div class="corner-right">
                    <div id="corner-bottomright"></div>
                </div>
            </div>
        </div>
        <div class="footer-area pt-5 position-relative">
            <div class="footer-corner-left">
                <img class="footer-corner-bottomleft" src="/assets/img/footer/left.svg">
            </div>
            <div class="footer-corner-right">
                <img class="footer-corner-bottomright" src="/assets/img/footer/right.svg">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-sm-8 col-md-8 mb-4 mb-xl-0 single-footer-widget">
                        <a class="navbar-brand" href="/"> <img src="/assets/img/logo_white.png" alt="logo"> </a>
                        <p class="mt-4">{{ $j('SiteDescription') }}</p>
                        <!-- <p>Tel: +99899-123-45-67</p> -->
                        <!-- <p class="mt-4">avtorfilm@gmail.com</p> -->
                    </div>
                    <div class="col-xl-2 col-sm-4 mb-4 mb-xl-0 single-footer-widget">
                        <h4>{{ $j('AboutUs') }}</h4>
                        <ul>
                            <li><a href="#">{{ $j('AboutAssociation') }}</a></li>
                            <li><a href="#">{{ $j('Management') }}</a></li>
                            <li><a href="#">{{ $j('Documents') }}</a></li>
                            <li><a href="#">{{ $j('Partners') }}</a></li>
                            <li><a href="#">{{ $j('Contacts') }}</a></li>
                        </ul>
                    </div>
                    <div class="col-xl-2 col-sm-4 mb-4 mb-xl-0 single-footer-widget">
                        <h4>{{ $j('Media') }}</h4>
                        <ul>
                            <li><a href="#">{{ $j('News') }}</a></li>
                            <li><a href="#">{{ $j('PhotoMaterials') }}</a></li>
                            <li><a href="#">{{ $j('VideoMaterials') }}</a></li>
                        </ul>
                    </div>
                    <div class="col-xl-2 col-sm-4 mb-4 mb-xl-0 single-footer-widget">
                        <h4>{{ $j('Users') }}</h4>
                        <ul>
                            <li><a href="#">{{ $j('Teleradio') }}</a></li>
                            <li><a href="#">{{ $j('Cinemas') }}</a></li>
                            <li><a href="#">{{ $j('CafeRestaurants') }}</a></li>
                            <li><a href="#">{{ $j('Shopping') }}</a></li>
                            <li><a href="#">{{ $j('Hotels') }}</a></li>
                        </ul>
                    </div>                
                </div>
                <div class="copyright_part">
                    <div class="row text-center">
                        <p class="footer-text m-0 col-lg-12 col-md-12">
                            Copyright &copy; {{ nowYear }} Authors. All right reserved
                            <!-- <a href="https://avtorfilm.uz" target="_blank">avtorfilm.uz</a>  -->
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data() {
            return {
				nowYear: 0
			};
        },
		mounted(){
			this.nowYear = new Date().getFullYear();
		}
    };
</script>