<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 m-b30">
                <div class="widget-box">
                    <div class="wc-title">
                        <h4>{{ $j('PersonalCabinet') }}</h4>
                    </div>
                    <div class="widget-inner">
                        <div class="card-user card-nav-tabs mt-4">
                            <div class="card-header card-header-primary">
                                <!-- colors: "header-primary", "header-info", "header-success", "header-warning", "header-danger" -->
                                <div class="nav-tabs-navigation">
                                    <div class="nav-tabs-wrapper">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                            <li class="nav-item">
                                                <a class="nav-link active" href="#profile" data-toggle="tab">
                                                    <i class="mdi mdi-account-check-outline"></i> {{ $j('PersonalInformation') }}
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#messages" data-toggle="tab">
                                                    <i class=" mdi mdi-map-marker-outline"></i> {{ $j('AddressInformation') }}
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#settings" data-toggle="tab">
                                                    <i class="mdi mdi-account-card-details-outline"></i> {{ $j('PassportInformation') }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body mt-4">
                                <div class="tab-content text-center">
                                    <div class="tab-pane active" id="profile">
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">FISH </label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ user.fullName }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('DateOfBirth') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ parseDate(user.birthDate) }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('PhoneNumber') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ user.mobilePhone }}</span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="tab-pane" id="messages">
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('Address') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ user.address }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="settings">
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('PassportSerialNumber') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ user.passportNumber }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('WhenGiven') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ parseDate(user.passportDate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">{{ $j('GivenByWho') }}</label>
                                            <div class="col-sm-4 d-flex justify-content-center align-items-center bg-label-secondary">
                                                <span class="user-info">{{ user.passportPlace }}</span>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
		return {
            user: {}
		};
	},
	methods: {
        parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
	},
	mounted() {
        this.user = this.$store.getters.getUserModel();
	}
}
</script>