<template>
    <div class="dynamic-page">
        <div class="container" v-if="model != null">
            <div class="breadcrump">
                <h4 class="py-3 mb-4">
                    <span class="text-muted fw-light">
                        <a href="/">{{ $j('Homepage') }}</a> <i class="mdi mdi-chevron-right"></i>
                    </span> {{ model['title' + capitalizeFirstLetter()] }}
                </h4>
            </div>
            <div v-html="model['body' + capitalizeFirstLetter()]"></div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                path: null,
                model: {}
            };
        },
        methods: {
            getData() {
                this.$api.get("/query_service/api/v1/dynamic_pages/get_dynamic_pages?" + 
                    "&pagePath=" + this.path +
                    "&offset=0&limit=10")
                .then(response => {
                    this.model = response.result.data[0];
                },
                err => {
                    this.$store.getters.errorParse(this, err);
                })
            },
            capitalizeFirstLetter() {
                let result = this.$store.state.langKod.charAt(0).toUpperCase() + this.$store.state.langKod.slice(1);
                return result.slice(0, -1)
            }
        },
        mounted() {
            this.getData();
        },
        created() {
            this.path = this.$route.params.id;
            if (!this.path) {
                this.$router.push({ path: "/404" });
            }
        }
    };
</script>