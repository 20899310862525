<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12 m-b30">
				<div class="widget-box">
					<div class="wc-title">
						<h4>{{ $j('Applications') }}</h4>
					</div>
					<div class="widget-inner">
						<div class="table-responsive" >
							<table class="table table-striped mb-0 text-center">
								<thead>
									<tr>
										<th>№</th>
										<th>{{ $j('HandwritingName') }}</th>
										<th>{{ $j('PieceType') }}</th>
										<th>{{ $j('Author') }}</th>
										<th>{{ $j('OwnerRights') }}</th>
										<th>{{ $j('PieceDate') }}</th>
										<th>{{ $j('RegistrationDate') }}</th>
										<th>{{ $j('DownloadedFile') }}</th>
										<th>{{ $j('certificate') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(key, value) in list" :key="value">
										<td>{{ value + 1 }}</td>
										<td>{{ key.productTitle }}</td>
										<td>{{ setPiece(key.productionTypeId) }} </td>
										<td>{{ key.applicant }} </td>
										<td>{{ key.productOwner }}</td>
										<td>{{ parseDate(key.productionDate) }}</td>
										<td>{{ parseDate(key.applicationDate) }}</td>
										<td>
											<span class="cursor-poiner" @click="downloadFile(key.applicationFilePath)" v-if="key.applicationFilePath != ''">
												<i class="ti-download"></i>
											</span>
										</td>
										<td v-html="statusi(key)"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-3 d-flex justify-content-center" v-if="this.pages > 1">
			<paginate 
				v-model="getPage"
				:page-count="pages"
				:page-range="3"
				:margin-pages="2"
				:click-handler="clickCallback"
				:prev-text="'＜'"
				:next-text="'＞'"
				:container-class="'pagination'"
				:page-class="'page-item'">
			</paginate>
			<form @submit.prevent="clickCallback(getPage)" >
				<input v-model="getPage" type="number" class="page-input" />
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Paginate from "vuejs-paginate";
export default {
	components: {
		Paginate
	},
    data() {
		return {
			pieces: [],
			list: [],
			limit: 10,
			offset: 0,
			pages: 0,
			getPage: 1,
		};
	},
	methods: {
		getPieces() {
			var _this = this;
			this.$api.get("/content_service/api/v1/production_types/get_production_type").then(response => {
				_this.pieces = response.result.data;				
				this.getData();
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		getData() {
			const headers = this.$store.getters.getHeader(this);
			axios({
				method: "get",
				url: this.$store.state.mainBaseUrl + "/certificate_service/api/v1/application/get_application?" + 
				"offset=" + this.offset +
				"&limit=" + this.limit,
				headers: headers
			}).then((response) => {
				this.list = response.data.result.data;
				this.pages = Math.ceil(response.data.result.count / this.limit);
			},
			err => {
				this.$store.getters.toastr(this, "error", err.response.data.error.errorMessage, "");
			});
		},
		
		downloadFile(fileUrl){
			var split = fileUrl.split("/");
			const link = document.createElement("a");
			link.href = this.$store.state.mainBaseUrl + fileUrl;
			link.setAttribute("download", split[2]);
			document.body.appendChild(link);
			link.click();
        },
		setPiece(val) {
			var piece = this.pieces.firstOrDefault(m => m.id == val)
			var result = piece.typeDescription;
			return result;
		},
		statusi(item) {
			var order = item.paymentOrder;
			var status = ""; var status1 = this.$j("Status1");
			var status2 = this.$j("Status2"); var status3 = this.$j("Status3");
			var query = "m=668f8445d1602a403ae3c335;ac.order_id=" + order.id + ";ac.user_id=" + order.applicantPinfl + ";a=1000";
			var base64 = btoa(query);
			switch (order.orderPaymentStatus)
			{
				case 1: status = (item.isFromLegalUser == false && item.productOwner == null) ? "<a href='https://checkout.paycom.uz/" + base64 + "' class='pay-badge rounded btn btn-warning ml-1'> " + status1 + "</a>" : ""; break;
				case 2: status = '<span class="pay-badge rounded bg-label-danger">' + status2 + '</span>'; break;
				case 3: status = '<span class="pay-badge rounded bg-label-success">' + status3 + '</span>'; break;
				default: break;
			}
			if(item.certificateGenerated){
				var split = item.certificatePath.split("/");
				status = "<a href='" + this.$store.state.mainBaseUrl + item.certificatePath + "' target='_blank' class='pay-badge rounded bg-label-purple' download='" + split[2] + "'> Yuklab olish</a>";
			}

			return status;
		},
		parseDate(date) {
			var parse = '';
			if(date){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
		clickCallback(page) {
			this.offset = (page - 1) * this.limit
			this.getData()
		},
	},
	mounted() {
		this.getPieces();
	}
}
</script>