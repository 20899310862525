<template>
    <div class="dynamic-page">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light"><a href="/">{{ $j('Homepage') }}</a> <i class="mdi mdi-chevron-right"></i></span> Teleradiokompaniyalar</h4>
            </div>
            <div class="reference-info pb-5">
                <h2 class="mb-3 pt-2">Teleradiokompaniyalar</h2>
                <img src="/assets/img/reference/broadcasters.png" class="img-fluid pt-3 mb-2">
                <h3 class="pt-3 pb-3">Teleradiokompaniyalar</h3>
                <p>Mualliflar uyushmasi, yozuvchilar va adabiyotshunoslar jamiyatining birlikda faollik ko'rsatish, adabiyotni rivojlantirish va tarqatish, yozuvchilar orasidagi hamkorlikni oshirish maqsadida tashkil etilgan bir tashkilotdir.</p>
                <p>Bu uyushma, O'zbekiston Respublikasi Xalq talimi vazirligi, Oliy va orta maxsus talim vazirligi, Ozbekiston Yozuvchilar uyushmasi bilan birgalikda faoliyat yuritadi. </p>
                <p>Mualliflar uyushmasi, adabiyot sohasidagi yaratuvchi va ilmiy-tadqiqot ishlari bilan shug'ullanuvchi shaxslar orasidagi hamkorlikni oshirish, adabiyotni tarqatish, yozuvchilar orasidagi aloqalarni rivojlantirish, yosh yozuvchilar va talabalar bilan faollik ko'rsatish maqsadida faoliyat yuritadi.</p>
                <div class="document-link mt-3 mb-4">
                    <ul>
                        <li>
                            <span>Jamoat tovarlari va havo (kabel bilan) va hajmini sotish uchun muallifning ish haqi to'g'risidagi nizom o'rni yo'llari soni, televizorda</span><br>
                            <a href="#"><i class="mdi mdi-file-pdf"></i> 1735-1735204-60173.pdf</a><hr>
                        </li>
                        <li>
                            <span>Jamoat tovarlari va havo (kabel bilan) va hajmini sotish uchun muallifning ish haqi to'g'risidagi nizom o'rni yo'llari soni, televizorda</span><br>
                            <a href="#"><i class="mdi mdi-file-pdf"></i> 1735-1735204-60173.pdf</a><hr>
                        </li>
                        <li>
                            <span>Jamoat tovarlari va havo (kabel bilan) va hajmini sotish uchun muallifning ish haqi to'g'risidagi nizom o'rni yo'llari soni, televizorda</span><br>
                            <i class="mdi mdi-file-pdf"></i> <a href="#"> 1735-1735204-60173.pdf</a><hr>
                        </li>
                    </ul>
                </div>
                <p>Mualliflar uyushmasi, yozuvchilar va adabiyotshunoslar jamiyatining birlikda faollik ko'rsatish, adabiyotni rivojlantirish va tarqatish, yozuvchilar orasidagi hamkorlikni oshirish maqsadida tashkil etilgan bir tashkilotdir.</p>
                <p>Bu uyushma, O'zbekiston Respublikasi Xalq talimi vazirligi, Oliy va orta maxsus talim vazirligi, Ozbekiston Yozuvchilar uyushmasi bilan birgalikda faoliyat yuritadi. </p>
                <p>Mualliflar uyushmasi, adabiyot sohasidagi yaratuvchi va ilmiy-tadqiqot ishlari bilan shug'ullanuvchi shaxslar orasidagi hamkorlikni oshirish, adabiyotni tarqatish, yozuvchilar orasidagi aloqalarni rivojlantirish, yosh yozuvchilar va talabalar bilan faollik ko'rsatish maqsadida faoliyat yuritadi.</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {};
</script>