export default {
  Homepage: "Домашняя страница",
  Login: "Lогин",
  Cabinet: "Кабинет",
  Logout: "Выйти",
  AboutAssociation: "Об ассоциации",
  Leadership: "Лидерство",
  Documents: "Документы",
  Partners: "Партнеры",
  Contacts: "Контакты",
  Deposition: "Депонирование",
  RightsHolders: "Правообладатели",
  Users: "Пользователи",
  Registers: "Реестры",
  News: "Новости",
  SubBroadcasting: "Телерадиовещательные компании",
  SubTheaters: "Театры",
  SubCinemas: "Кинотеатры",
  SubBarsAndRestaurants: "Бары и рестораны",
  SubTrading: "Торговые компании",
  SubMusicVenues: "Музыкальные и развлекательные заведения",
  SubHotels: "Отели",
  SubPassengerTransportation: "Пассажирские транспортные компании",
  SubPublicEvents: "Публичные мероприятия",
  SubPaymentsByInstall: "Оплата частями",
  SubServiceSector: "Предприятия сферы услуг",
  WelcomeSite: "Добро пожаловать в Ассоциацию Авторов",
  SiteTitle: "Ассоциация представителей киноиндустрии Узбекистана",
  SiteDescription:
    "Он заключается в формировании эффективной системы защиты авторских прав путем осуществления коллективного управления правами авторов в аудиовизуальной сфере.",
  OurPartner: "Наши партнеры",
  GeneralInformation: "общая информация",
  AuthorsUnion: "Авторское объединение",
  AuthorsUnionText1:
    "Союз авторов — организация, созданная в целях совместной деятельности общества писателей и литературоведов, развития и распространения литературы, расширения сотрудничества между писателями.",
  AuthorsUnionText2:
    "Данное объединение работает совместно с Министерством народного образования Республики Узбекистан, Министерством высшего и среднего специального образования, Союзом писателей Узбекистана.",
  AuthorsUnionText3:
    "Ассоциация авторов действует в целях расширения сотрудничества между творцами и лицами, занимающимися научной и исследовательской работой в области литературы, распространения литературы, развития связей между писателями, проявления активности с молодыми литераторами и студентами.",
  More: "Более",
  YearsOfExperience: "Годы опыта",
  Employees: "Сотрудники",
  Management: "Управление",
  PhotoMaterials: "Фотоматериалы",
  VideoMaterials: "Видео материалы",
  All: "Все",
  AboutUs: "О нас",
  Media: "Медиа",
  Teleradio: "Телерадиокомпании",
  Cinemas: "Кинотеатры",
  CafeRestaurants: "Кафе и рестораны",
  Shopping: "Торговые комплексы",
  Hotels: "Отели",
  DataEntry: "Ввод данных",
  FileUpload: "Загрузить файл",
  DataVerification: "Проверка данных",
  Payment: "Оплата",
  DepositionProcess: "Процедура депонирования",
  ProcessText:
    "Вы можете хранить файл любого произведения размером до 5 ГБ на срок от 2 до 10 лет.",
  HandwritingName: "Название произведения",
  Next: "Следующий",
  Prev: "Предыдущий",
  DropFiles: "Перекиньте файлы сюда или",
  SelectFromDevice: "Выберите файл на устройстве",
  Applicant: "Заявитель",
  Address: "Адрес",
  PassportSerialNumber: "Серийный номер паспорта",
  DownloadedFile: "Загруженный файл",
  ServicePrice: "Стоимость услуги",
  Save: "Сохранить",
  PaymentText:
    "Для совершения оплаты на сайте выберите один из предложенных способов оплаты: Payme, Click. Далее следуйте инструкциям на сайте платежной системы.",
  PaymentText2:
    "После оплаты проверьте статус вашей заявки и дождитесь одобрения.",
  ProceedToPayment: "Перейти к оплате",
  Applications: "Приложения",
  PersonalCabinet: "Личный кабинет",
  PersonalInformation: "Персональная информация",
  AddressInformation: "Информация об адресе",
  PassportInformation: "Паспортные данные",
  DateOfBirth: "Дата рождения",
  PhoneNumber: "Номер телефона",
  WhenGiven: "Когда дано",
  GivenByWho: "Кем дано",
  Author: "Автор",
  RegistrationDate: "Дата регистрации",
  Status: "Статус",
  Status1: "Платеж ожидает",
  Status2: "Отменено",
  Status3: "Оплаченный",
  PieceType: "Тип произведения",
  OwnerRights: "Правообладатель",
  PieceDate: "Дата создания произведения",
  OwnAuthor: "Я автор",
  OrgAddress: "Адрес",
  Director: "FIO руководителя",
  notEntered: "не выбран!",
  notSelected: "не выбран!",
  errorEntered: "было введено неверно!",
  legalPayInfo:
    "Платежи для юридических лиц производятся путем перечисления денег с их банковского счета на основании договора.",
  termsNotClick: "Условия эксплуатации",
  termsAccept: "Я согласен с условиями депонирования",
  termsPage: "Пожалуйста, ознакомьтесь",
  certificate: "Сертификат",
};