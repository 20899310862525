<template>
    <header class="ttr-header">
        <div class="ttr-header-wrapper">
            <div class="ttr-toggle-sidebar ttr-material-button">
                <i class="ti-close ttr-open-icon"></i>
                <i class="ti-menu ttr-close-icon"></i>
            </div>
            <div class="ttr-logo-box">
                <a href="/" class="ttr-logo">
                    <img alt="" class="ttr-logo-mobile" src="/favicon/android-chrome-192x192.png" height="50">
                </a>
            </div>
            <div class="ttr-header-right ttr-with-seperator">
                <ul class="ttr-header-navigation">
                    <!-- <li>
                        <a href="#" class="ttr-material-button ttr-submenu-toggle">O‘zbek <i class="mdi mdi-chevron-down"></i></a>
                        <div class="ttr-header-submenu">
                            <ul>
                                <li><a href="#">Русский</a></li>
                                <li><a href="#">English</a></li>
                            </ul>
                        </div>
                    </li> -->
                    <li>
                        <a href="#" class="ttr-material-button ttr-submenu-toggle d-flex align-items-center">
                            <span class="ttr-user-avatar">
                                <img alt="" src="/assets/img/users/avatar.jpg" width="32" height="32">
                            </span>
                            <span class="ttr-material-button ttr-submenu-toggle ml-2">{{ login }} <i class="mdi mdi-chevron-down"></i></span>
                        </a>
                        <div class="ttr-header-submenu">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" @click="logout()">
                                        <i class="mdi mdi-logout"></i>
                                        <span>{{ $j('Logout') }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>
    export default {
        data() {
            return {
                login: null,
            };
        },
        methods: {
            logout() {
                this.$store.getters.deleteCookie("accessCookie");
                this.$store.getters.deleteCookie("userCookie");
                location.href = "/";
            },
        },
        mounted() {
            this.login = this.$store.getters.getLogin();
            let app = document.createElement('script');
            app.setAttribute('src', '/account/js/custom.js');
            document.getElementById("divScript").appendChild(app);
        }
    };
</script>