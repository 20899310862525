export default {
  Homepage: "Homepage",
  Login: "Login",
  Cabinet: "Cabinet",
  Logout: "Logout",
  AboutAssociation: "About Association",
  Leadership: "Leadership",
  Documents: "Documents",
  Partners: "Partners",
  Contacts: "Contacts",
  Deposition: "Deposition",
  RightsHolders: "Rights Holders",
  Users: "Users",
  Registers: "Registers",
  News: "News",
  SubBroadcasting: "Broadcasting",
  SubTheaters: "Theaters",
  SubCinemas: "Cinemas",
  SubBarsAndRestaurants: "Bars and restaurants",
  SubTrading: "Trading companies",
  SubMusicVenues: "Music and entertainment venues",
  SubHotels: "Hotels",
  SubPassengerTransportation: "Passenger transportation companies",
  SubPublicEvents: "Public events",
  SubPaymentsByInstall: "Payments by installments",
  SubServiceSector: "Service sector enterprises",
  WelcomeSite: "Welcome to the Authors Association",
  SiteTitle: "Union of representatives of the film industry of Uzbekistan",
  SiteDescription:
    "Consists of forming an effective copyright protection system by implementing collective management of authors' rights in the audiovisual sector",
  OurPartner: "Our partners",
  GeneralInformation: "general information",
  AuthorsUnion: "Authors Union",
  AuthorsUnionText1:
    "The Authors' Union is an organization of the society of writers and literary experts, which was established in order to show activity in unity, to develop and distribute literature, and to increase cooperation between writers.",
  AuthorsUnionText2:
    "This association works together with the Ministry of Public Education of the Republic of Uzbekistan, the Ministry of Higher and Secondary Special Education, the Writers' Union of Uzbekistan.",
  AuthorsUnionText3:
    "The Authors' Union works to increase cooperation between creative and research workers in the field of literature, to distribute literature, to develop relations between writers, and to show activity with young writers and students.",
  More: "More",
  YearsOfExperience: "Years of experience",
  Employees: "Employees",
  Management: "Partners",
  PhotoMaterials: "Photo materials",
  VideoMaterials: "Video materials",
  All: "All",
  AboutUs: "About Us",
  Media: "Media",
  Teleradio: "Teleradio companies",
  Cinemas: "Cinemas",
  CafeRestaurants: "Cafes and restaurants",
  Shopping: "Shopping complexes",
  Hotels: "Hotels",
  DataEntry: "Data entry",
  FileUpload: "Upload file",
  DataVerification: "Data verification",
  Payment: "Payment",
  DepositionProcess: "Deposition process",
  ProcessText:
    "You can deposit a file of any work up to 5 GB for 2 to 10 years.",
  HandwritingName: "The name of the work",
  Next: "Next",
  Prev: "Prev",
  DropFiles: "Drop the files here or",
  SelectFromDevice: "Select the file from the device",
  Applicant: "Applicant",
  Address: "Address",
  PassportSerialNumber: "Passport serial number",
  DownloadedFile: "Downloaded file",
  ServicePrice: "Service price",
  Save: "Save",
  PaymentText:
    "To make a payment on the site, choose one of the proposed payment methods: Payme, Click. Then follow the instructions on the payment system's website",
  PaymentText2:
    "After making the payment, check the status of your application and wait for approval.",
  ProceedToPayment: "Proceed to payment",
  Applications: "Applications",
  PersonalCabinet: "Personal cabinet",
  PersonalInformation: "Personal information",
  AddressInformation: "Address information",
  PassportInformation: "Passport information",
  DateOfBirth: "Date of birth",
  PhoneNumber: "Phone number",
  WhenGiven: "When given",
  GivenByWho: "Given by whom",
  Author: "Author",
  RegistrationDate: "Registration date",
  Status: "Status",
  Status1: "Payment is pending",
  Status2: "Canceled",
  Status3: "Paid",
  PieceType: "Type of work",
  OwnerRights: "Owner of rights",
  PieceDate: "The date the work was",
  OwnAuthor: "I am the author",
  OrgAddress: "Location address",
  Director: "Executive's F.I.O",
  notEntered: "not selected!",
  notSelected: "not selected!",
  errorEntered: "entered incorrectly!",
  legalPayInfo:
    "Payments for legal entities are made by transferring money from their bank account on the basis of the contract",
  termsNotClick: "Terms of use",
  termsAccept: "I agree to the terms of use",
  termsPage: "Please read",
  certificate: "Certificate",
};